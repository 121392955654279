import { StringResources } from "utils/language/languageResource";
import { useTranslation } from "react-i18next";
import { DownloadIcon } from "components/icons";
import BaseButton from "components/baseButton/baseButton";
import "./settlementsHeader.scss";
import { RootState, useAppDispatch } from "redux/store";
import { getDownloadPdfFileMetadata, saveFile } from "utils/helperFunctions";
import { useSelector } from "react-redux";
import { IGetSettlementsResponseListData } from "../api/settlementsModels";
import { toast } from "react-toastify";
import { getSettlementsDetailsReport } from "../api/settlementsApi";

const SettlementsHeader = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const activeRowData: IGetSettlementsResponseListData = useSelector((state: RootState) => state.report.activeRow);

    const handleClick = async () => {
        try {
            const response = await getSettlementsDetailsReport({ settlementId: activeRowData.id });
            saveFile(response.data, response.filename, response.contentType);
        } catch (error) {
            toast.error(t(StringResources.pages.settlements.printReportError).toString());
        }
    };

    return (
        <div className="settlements-header report-details__title">
            <div className="report-details__title--label">{`${t(StringResources.pages.settlements.title)}`}</div>
            <div className="report-details__title--action">
                <BaseButton
                    handleClick={handleClick}
                    text={`${t(StringResources.pages.settlements.print)}`}
                    styleType="line"
                    leftIcon={<DownloadIcon />}
                    size="small"
                />
            </div>
        </div>
    );
};

export default SettlementsHeader;
