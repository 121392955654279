import React, { useState } from "react";
import { ColorResult, SketchPicker } from "react-color";
import "./colorPicker.scss";

interface ColorPickerInputProps {
    onChange: (color: string) => void;
    onBlur: () => void;
    colorPalette: string;
}

const ColorPicker: React.FC<ColorPickerInputProps> = ({ colorPalette, onChange, onBlur }) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false);

    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker);
    };

    const handleClose = () => {
        setDisplayColorPicker(false);
        onBlur();
    };

    const handleColorChange = (colorResult: ColorResult) => {
        onChange(colorResult.hex);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
    };

    return (
        <div className="color-picker">
            <div className="color-picker--container" onClick={handleClick}>
                <input className="color-picker--container__input" value={colorPalette} onChange={handleInputChange} />
                <div className="color-picker--container__swatch" style={{ background: colorPalette }} />
            </div>

            {displayColorPicker ? (
                <div className="color-picker--popover">
                    <div className="color-picker--popover__cover" onClick={handleClose} />
                    <SketchPicker color={colorPalette} onChange={handleColorChange} />
                </div>
            ) : null}
        </div>
    );
};

export default ColorPicker;
