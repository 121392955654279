import classNames from "classnames";
import BaseButton from "components/baseButton/baseButton";
import BaseDatepicker from "components/baseDatepicker/baseDatepicker";
import BaseInput from "components/baseInput/baseInput";
import BaseRadio from "components/baseRadio/baseRadio";
import BaseSelect, { Option } from "components/baseSelect/baseSelect";
import BaseToggleSwitch from "components/baseToggleSwitch/baseToggleSwitch";
import Decimal from "decimal.js";
import { useFormik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { confirmModal, declineModal } from "redux/reducers/modalReducer";
import { executeAxiosRequestWithRefresh } from "redux/services";
import { useAppDispatch } from "redux/store";
import { ModalTypeEnum, ShopperInfoRequirementType, SupportedLanguageEnum } from "utils/enums";
import { Currency, formatter } from "utils/formatter";
import { createUUID, getLanguages } from "utils/helperFunctions";
import { StringResources } from "utils/language/languageResource";
import * as Yup from "yup";
import "./createPaymentLinkModal.scss";
import {
    CreatePaymentLinkModalProps,
    CreatePaymentLinkSalesType,
    IBuyerInfoData,
    ICreatePaymentLinkRequestForm,
    IEcomResponseData,
    IPaymentLinkShopperInfo,
    IPaymentLinkThemeResponseData,
    IProductItemsData,
    IProductRow,
    shopperInfoDataInitialValues,
} from "./createPaymentLinkModel";
import OpacityLoader from "components/baseLoader/opacityLoader";
import BaseCurrencyInputATM from "components/baseCurrencyInput/baseCurrencyInputATM";
import CancelInputIcon from "components/icons/cancelInputIcon";
import PlussAddIcon from "components/icons/plusAddIcon";

const languages = getLanguages();

const CreatePaymentLinkModal = (props: CreatePaymentLinkModalProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [paymentPage, setPaymentPage] = useState<Array<IEcomResponseData>>([]);
    const [paymentLinkThemeOptions, setPaymentLinkThemeOptions] = useState<Array<Option>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const formValidation = useFormik<ICreatePaymentLinkRequestForm>({
        enableReinitialize: false,
        validateOnChange: true,
        validateOnMount: false,
        validateOnBlur: true,
        initialValues: {
            ecomSolutionId: undefined,
            contractId: undefined,
            currencyId: "ISK",
            paymentLinkTheme: undefined,
            payByLinkDescription: undefined,
            purchaseReturnUrl: undefined,
            language: SupportedLanguageEnum.Icelandic,
            dateTo: undefined,
            salesType: CreatePaymentLinkSalesType.UnlimitedSales,
            exactSalesCount: undefined,
            hasExpirationDate: undefined,
            shopperInfoData: [...shopperInfoDataInitialValues.map(x => ({ ...x, label: t(x.label) }))],
            productValues: [
                {
                    productDetailsId: createUUID(),
                    description: undefined,
                    quantity: 1,
                    unitPrice: 0,
                    amount: 0,
                },
            ],
        },
        validationSchema: Yup.object({
            ecomSolutionId: Yup.string().required(t(StringResources.modal.createPaymentLink.paymentPageRequired)),
            language: Yup.number().required(t(StringResources.modal.createPaymentLink.languageRequired)),
            salesType: Yup.number().required(t(StringResources.modal.createPaymentLink.salesTypeRequired)),
            exactSalesCount: Yup.number().when("salesType", {
                is: CreatePaymentLinkSalesType.ExactSales,
                then: Yup.number().required(t(StringResources.modal.createPaymentLink.exactSalesCountRequired)),
                otherwise: Yup.number().notRequired(),
            }),
            hasExpirationDate: Yup.boolean().required(
                t(StringResources.modal.createPaymentLink.hasExpirationDateRequired)
            ),
            dateTo: Yup.date().when("hasExpirationDate", {
                is: true,
                then: Yup.date().required(t(StringResources.modal.createPaymentLink.validToRequired)),
                otherwise: Yup.date().notRequired(),
            }),
            productValues: Yup.array().of(
                Yup.object().shape({
                    description: Yup.string().required(
                        `${t(StringResources.modal.createPaymentLink.descriptionRequired)}`
                    ),
                    quantity: Yup.number()
                        .min(1, `${t(StringResources.modal.createPaymentLink.quantityMustBeAtLeastOne)}`)
                        .required(`${t(StringResources.modal.createPaymentLink.quantityIsRequired)}`),
                    unitPrice: Yup.number()
                        .test(
                            "is-greater-than-zero",
                            t(StringResources.modal.createPaymentLink.unitPriceMustBeHigherThanZero),
                            value => value! > 0
                        )
                        .required(t(StringResources.modal.createPaymentLink.unitPriceMustBeHigherThanZero)),
                    amount: Yup.number()
                        .test(
                            "is-lower-or-equal",
                            `${t(StringResources.modal.createPaymentLink.amountGreaterThanAmountWithoutDiscount)}`,
                            function (value) {
                                const amountWithoutDiscount = new Decimal(
                                    (this.parent.quantity ?? 0) * (this.parent.unitPrice ?? 0)
                                ).toNumber();

                                return value! <= amountWithoutDiscount;
                            }
                        )
                        .required(),
                })
            ),
            purchaseReturnUrl: Yup.string().matches(
                /^(https?:\/\/)([a-z0-9-]+(\.[a-z0-9-]+)*)\/?([a-zA-Z0-9#-_]+\/?)*(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                t(StringResources.modal.createPaymentLink.incorrectUrl)
            ),
        }),
        onSubmit: async (value: ICreatePaymentLinkRequestForm) => {
            // don't send if amount is 0 for the last
            let prodValuesForRequest = value.productValues.map(item => ({
                productDetailsId: Number.isInteger(item.productDetailsId) ? +item.productDetailsId : undefined,
                description: item.description,
                quantity: item.quantity,
                unitPrice: item.unitPrice,
                amountWithoutDiscount: new Decimal((item.quantity ?? 0) * (item.unitPrice ?? 0)).toNumber(),
                amount: item.amount,
            }));

            // don't send ones that are hidden because we don't save them to db
            let shopperInfoDataForRequest = formValidation.values.shopperInfoData
                .filter(x => x.shopperInfoRequirementType != ShopperInfoRequirementType.Hidden)
                .map(({ label, id, ...item }) => item);

            if (!props.data.isUpdate || props.data.isCopy) {
                try {
                    await executeAxiosRequestWithRefresh({
                        url: `/api/paymentLink/create-payment-link`,
                        method: "POST",
                        data: {
                            ecomSolutionId: value.ecomSolutionId,
                            currencyId: value.currencyId,
                            paymentLinkThemeId: value.paymentLinkTheme,
                            payByLinkDescription: value.payByLinkDescription,
                            languageType: value.language,
                            purchaseReturnUrl: value.purchaseReturnUrl,
                            validTo: value.dateTo,
                            shopperInfos: shopperInfoDataForRequest,
                            exactSalesCount: value.exactSalesCount,
                            productDetails: prodValuesForRequest,
                            totalAmount: calculateTotalAmount(),
                        },
                    });
                    dispatch(confirmModal({ modalType: ModalTypeEnum.CreatePaymentLink }));

                    if (props.data.isUpdate) {
                        toast.success(`${t(StringResources.modal.createPaymentLink.successUpdateMessage)}`);
                    } else {
                        toast.success(`${t(StringResources.modal.createPaymentLink.successMessage)}`);
                    }
                } catch (error: any) {
                    toast.error(`${t(StringResources.modal.createPaymentLink.errorMessage)}`);
                }
                return;
            }

            try {
                await executeAxiosRequestWithRefresh({
                    url: `/api/paymentLink/update-payment-link`,
                    method: "POST",
                    data: {
                        paymentLinkId: props.data.paymentLinkId!,
                        ecomSolutionId: value.ecomSolutionId,
                        currencyId: value.currencyId,
                        paymentLinkThemeId: value.paymentLinkTheme,
                        payByLinkDescription: value.payByLinkDescription,
                        languageType: value.language,
                        purchaseReturnUrl: value.purchaseReturnUrl,
                        validTo: value.dateTo,
                        shopperInfos: shopperInfoDataForRequest,
                        exactSalesCount: value.exactSalesCount,
                        productDetails: prodValuesForRequest,
                        totalAmount: calculateTotalAmount(),
                    },
                });
                dispatch(confirmModal({ modalType: ModalTypeEnum.CreatePaymentLink }));
                toast.success(`${t(StringResources.modal.createPaymentLink.successUpdateMessage)}`);
            } catch (error) {
                toast.error(`${t(StringResources.modal.createPaymentLink.errorUpdateMessage)}`);
            }
        },
    });

    useEffect(() => {
        const fetchSelectOptions = async () => {
            try {
                const response = await executeAxiosRequestWithRefresh({
                    url: `/api/paymentLink/get-pay-by-link-request-data`,
                    method: "GET",
                });

                if (response && response.data) {
                    const ecomResponseData = response.data.ecomResponseData;
                    const paymentLinkThemeResponseData = response.data.paymentLinkThemeResponseData;

                    setPaymentPage(ecomResponseData);

                    const paymentLinkThemeOptions: Array<Option> = paymentLinkThemeResponseData.map(
                        (x: IPaymentLinkThemeResponseData) => {
                            return {
                                label: `${x.themeId} - ${x.themeName} ${
                                    x.themeDescription ? "- " + x.themeDescription : ""
                                }`,
                                value: x.themeId,
                                selected: false,
                            };
                        }
                    );

                    setPaymentLinkThemeOptions(paymentLinkThemeOptions);

                    setIsLoading(false);
                }
            } catch (error) {
                toast.error(`${t(StringResources.modal.createPaymentLink.fetchPaymentPageError)}`);
            }
        };

        const fetchPaymentLinkDetailsData = async () => {
            try {
                const paymentLinkId = props.data.paymentLinkId;
                const response = await executeAxiosRequestWithRefresh({
                    url: `/api/paymentLink/details`,
                    method: "GET",
                    params: { paymentLinkId },
                });

                if (response && response.data) {
                    const productResponseData = response.data.productItemsData;
                    const shopperInfoResponseData = response.data.shopperInfoData;

                    const productItems: Array<IProductRow> = productResponseData.map((x: IProductItemsData) => {
                        return {
                            productDetailsId: x.id,
                            description: x.description,
                            quantity: x.quantity,
                            amount: new Decimal(x.amount).toNumber(),
                            unitPrice: new Decimal(x.unitPrice).toNumber(),
                        };
                    });

                    const shopperInfoData: Array<IPaymentLinkShopperInfo> = shopperInfoResponseData.map(
                        (x: IBuyerInfoData) => {
                            return {
                                id: x.id,
                                shopperInfoRequirementType: x.shopperInfoRequirementType,
                                field: x.shopperInfoFieldEnum,
                            };
                        }
                    );

                    const updatedShopperInfoData = shopperInfoDataInitialValues.map(item1 => {
                        const matchingItem = shopperInfoData.find(item2 => item2.field === item1.field);

                        return matchingItem
                            ? {
                                  ...item1,
                                  shopperInfoRequirementType: matchingItem.shopperInfoRequirementType,
                                  label: t(item1.label),
                              }
                            : {
                                  ...item1,
                                  label: t(item1.label),
                                  shopperInfoRequirementType: item1.shopperInfoRequirementType,
                              };
                    });

                    let dateTo = undefined;
                    let hasExpirationDate = undefined;

                    if (props.data.isCopy) {
                        dateTo = undefined;
                    } else {
                        if (response.data.validTo !== undefined && response.data.validTo !== null) {
                            hasExpirationDate = true;
                            dateTo = response.data.validTo;
                        } else {
                            hasExpirationDate = false;
                            dateTo = undefined;
                        }
                    }

                    let salesType = CreatePaymentLinkSalesType.UnlimitedSales;
                    let exactSalesCount = undefined;

                    if (response.data.exactSalesCount === 1) {
                        salesType = CreatePaymentLinkSalesType.SingleSales;
                        exactSalesCount = 1;
                    } else if (response.data.exactSalesCount > 1) {
                        salesType = CreatePaymentLinkSalesType.ExactSales;
                        exactSalesCount = response.data.exactSalesCount;
                    }

                    await formValidation.setValues({
                        ecomSolutionId: response.data.ecomSolutionId,
                        contractId: response.data.contractId,
                        paymentLinkTheme: response.data.paymentLinkThemeId,
                        currencyId: response.data.currencyId,
                        payByLinkDescription: response.data.paymentLinkDescription ?? "",
                        purchaseReturnUrl: response.data.purchaseReturnUrl ?? "",
                        language: response.data.languageType,
                        dateTo: dateTo,
                        salesType: salesType,
                        exactSalesCount: exactSalesCount,
                        hasExpirationDate: hasExpirationDate,
                        shopperInfoData: updatedShopperInfoData,
                        productValues: productItems,
                    });

                    setIsLoading(false);
                }
            } catch (error) {
                toast.error(`${t(StringResources.modal.createPaymentLink.fetchPaymentLinkDetailsError)}`);
            }
        };

        // todo change after be is merged
        fetchSelectOptions();

        if (props.data.isUpdate || props.data.isCopy) {
            setIsLoading(true);
            fetchPaymentLinkDetailsData();

            formValidation.setErrors({});
        }
    }, []);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        formValidation.handleSubmit();
    };

    const onCancelClick = () => {
        dispatch(declineModal({ modalType: ModalTypeEnum.CreatePaymentLink }));
    };

    const onOkClick = async () => {
        formValidation.handleSubmit();
    };

    const onPaymentPageChange = async (selectedItem: Option | undefined) => {
        if (selectedItem && selectedItem.value !== 0) {
            const contractId = +selectedItem.value.toString().split("-")[0];
            const ecomSolutionId = +selectedItem.value.toString().split("-")[1];

            await formValidation.setFieldValue("ecomSolutionId", ecomSolutionId);
            await formValidation.setFieldValue("contractId", contractId);

            const currency = paymentPage.find(
                x => x.ecomSolutionId == ecomSolutionId && x.contractId == contractId
            )!.currencyId;

            await formValidation.setFieldValue("currencyId", currency);
            formValidation.setFieldValue("productValues", [
                {
                    id: createUUID(),
                    description: undefined,
                    quantity: 1,
                    unitPrice: 0,
                    amount: 0,
                    currencyId: currency as Currency,
                },
            ]);
        } else {
            await formValidation.setFieldValue("ecomSolutionId", undefined);
            await formValidation.setFieldValue("contractId", undefined);
            await formValidation.setFieldValue("currencyId", undefined);
        }
    };

    const onOptionValueChange = async (
        selectedItem: Option | undefined,
        fieldName: "hasExpirationDate" | "paymentLinkTheme" | "language"
    ) => {
        if (selectedItem && selectedItem.value !== 0) {
            await formValidation.setFieldValue(fieldName, selectedItem.value);
        } else {
            await formValidation.setFieldValue(fieldName, undefined);
        }
    };

    const onBaseRadioChange = async (selectedRadio: number) => {
        await formValidation.setFieldValue("salesType", selectedRadio);

        if (selectedRadio == CreatePaymentLinkSalesType.SingleSales) {
            await formValidation.setFieldValue("exactSalesCount", 1);
        } else {
            await formValidation.setFieldValue("exactSalesCount", undefined);
        }
    };

    const getTodayDate = () => {
        const today = new Date();

        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0");
        const day = String(today.getDate()).padStart(2, "0");

        return `${year}-${month}-${day}`;
    };

    const calculateTotalAmount = () => {
        return formValidation.values["productValues"]
            .filter(x => x.amount != undefined)
            .map(x => new Decimal(Number(x.amount!)))
            .reduce((a, b) => a.plus(b), new Decimal(0))
            .toNumber();
    };

    const getFirstFormikError = () => {
        for (const key in formValidation.errors) {
            //@ts-ignore
            const error = formValidation.errors[key];

            if (typeof error === "string" && error !== undefined) {
                return error;
            }

            if (Array.isArray(error)) {
                for (const nestedObject of error) {
                    for (const nestedKey in nestedObject) {
                        const nestedError = nestedObject[nestedKey];
                        if (typeof nestedError === "string" && nestedError !== undefined) {
                            return nestedError;
                        }
                    }
                }
            }
        }

        return null; // Return null if no error found
    };

    return (
        <Modal show={true} onHide={onCancelClick} dialogClassName="base-modal" size="lg">
            <Form onSubmit={handleSubmit} noValidate>
                <Modal.Header closeButton className={classNames({ "modal-border-bottom": true })}>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="create-payment-link__modal-body">
                    {isLoading ? (
                        <div className="create-payment-link__modal-loader">
                            <OpacityLoader />
                        </div>
                    ) : (
                        <>
                            <div className="create-payment-link__settings-buyer-info">
                                <div className="create-payment-link__settings">
                                    <div className="create-payment-link__subtitle">{`${t(
                                        StringResources.modal.createPaymentLink.settingsTitle
                                    )}`}</div>
                                    <div className="create-payment-link__settings-input">
                                        <BaseSelect
                                            label={`${t(
                                                StringResources.modal.createPaymentLink.paymentPageOptionsLabel
                                            )}`}
                                            options={paymentPage.map((x: IEcomResponseData) => {
                                                return {
                                                    label: `${x.contractNumber} - ${x.dbaName} - ${x.currencyId} - ${x.tid}`,
                                                    value: `${x.contractId}-${x.ecomSolutionId}`,
                                                    selected:
                                                        `${x.contractId}-${x.ecomSolutionId}` ===
                                                        `${formValidation.values["contractId"]}-${formValidation.values["ecomSolutionId"]}`,
                                                };
                                            })}
                                            onChange={option => onPaymentPageChange(option)}
                                            isClearable={false}
                                            invalid={
                                                formValidation.errors["ecomSolutionId"] &&
                                                formValidation.touched["ecomSolutionId"]
                                                    ? formValidation.errors["ecomSolutionId"]
                                                    : false
                                            }
                                            onBlur={formValidation.handleBlur}
                                            toolTipDefinitions={[
                                                `${t(StringResources.modal.createPaymentLink.paymentPageToolTip)}`,
                                            ]}
                                        ></BaseSelect>
                                    </div>
                                    <div className="create-payment-link__settings-input">
                                        <BaseSelect
                                            label={`${t(
                                                StringResources.modal.createPaymentLink.paymentLinkThemeOptionsLabel
                                            )}`}
                                            options={paymentLinkThemeOptions.map(x => {
                                                return {
                                                    ...x,
                                                    selected: x.value == formValidation.values["paymentLinkTheme"],
                                                };
                                            })}
                                            onChange={option => onOptionValueChange(option, "paymentLinkTheme")}
                                            isClearable={true}
                                            invalid={
                                                formValidation.errors["paymentLinkTheme"] &&
                                                formValidation.touched["paymentLinkTheme"]
                                                    ? formValidation.errors["paymentLinkTheme"]
                                                    : false
                                            }
                                            onBlur={e => {
                                                formValidation.setTouched({ paymentLinkTheme: true });
                                                formValidation.handleBlur(e);
                                            }}
                                            toolTipDefinitions={[
                                                `${t(StringResources.modal.createPaymentLink.paymentPageThemeToolTip)}`,
                                            ]}
                                        ></BaseSelect>
                                    </div>
                                    <div className="create-payment-link__settings-input">
                                        <BaseInput
                                            type={"text"}
                                            name="payByLinkDescription"
                                            value={formValidation.values["payByLinkDescription"]}
                                            label={`${t(StringResources.modal.createPaymentLink.payByLinkDescription)}`}
                                            onBlur={formValidation.handleBlur}
                                            onChange={formValidation.handleChange}
                                            toolTipDefinitions={[
                                                `${t(
                                                    StringResources.modal.createPaymentLink.payByLinkDescriptionToolTip
                                                )}`,
                                            ]}
                                        ></BaseInput>
                                    </div>
                                    <div className="create-payment-link__settings-input">
                                        <BaseInput
                                            type="text"
                                            name="purchaseReturnUrl"
                                            invalid={
                                                formValidation.touched["purchaseReturnUrl"] &&
                                                formValidation.errors["purchaseReturnUrl"]
                                            }
                                            value={formValidation.values["purchaseReturnUrl"]}
                                            label={`${t(StringResources.modal.createPaymentLink.purchaseReturnUrl)}`}
                                            onBlur={formValidation.handleBlur}
                                            onChange={formValidation.handleChange}
                                            toolTipDefinitions={[
                                                `${t(
                                                    StringResources.modal.createPaymentLink.purchaseReturnUrlToolTip
                                                )}`,
                                            ]}
                                        ></BaseInput>
                                    </div>
                                    <div className="create-payment-link__settings-input">
                                        <BaseSelect
                                            label={`${t(StringResources.modal.createPaymentLink.languageOptionsLabel)}`}
                                            options={languages.map(x => {
                                                return {
                                                    label:
                                                        x.name == "Icelandic"
                                                            ? `${t(
                                                                  StringResources.enums.language[
                                                                      SupportedLanguageEnum.Icelandic
                                                                  ]
                                                              )}`
                                                            : `${t(
                                                                  StringResources.enums.language[
                                                                      SupportedLanguageEnum.English
                                                                  ]
                                                              )}`,
                                                    value: x.value,
                                                    selected: x.value == formValidation.values["language"],
                                                };
                                            })}
                                            onChange={option => onOptionValueChange(option, "language")}
                                            onBlur={e => {
                                                formValidation.setTouched({ language: true });
                                                formValidation.handleBlur(e);
                                            }}
                                            invalid={
                                                formValidation.errors["language"] && formValidation.touched["language"]
                                                    ? formValidation.errors["language"]
                                                    : false
                                            }
                                            isClearable={false}
                                            toolTipDefinitions={[
                                                `${t(StringResources.modal.createPaymentLink.defaultLanguageToolTip)}`,
                                            ]}
                                        ></BaseSelect>
                                    </div>
                                    <div className="create-payment-link__settings-input">
                                        <BaseSelect
                                            label={`${t(StringResources.modal.createPaymentLink.hasExpirationDate)}`}
                                            options={[
                                                {
                                                    label: `${t(
                                                        StringResources.modal.createPaymentLink.doesNotExpire
                                                    )}`,
                                                    value: false,
                                                    selected: formValidation.values["hasExpirationDate"] === false,
                                                },
                                                {
                                                    label: `${t(
                                                        StringResources.modal.createPaymentLink.hasExpirationDate
                                                    )}`,
                                                    value: true,
                                                    selected: formValidation.values["hasExpirationDate"] === true,
                                                },
                                            ]}
                                            onChange={option => onOptionValueChange(option, "hasExpirationDate")}
                                            isClearable={false}
                                            invalid={
                                                formValidation.errors["hasExpirationDate"] &&
                                                formValidation.touched["hasExpirationDate"]
                                                    ? formValidation.errors["hasExpirationDate"]
                                                    : false
                                            }
                                            onBlur={e => {
                                                formValidation.setTouched({ hasExpirationDate: true });
                                                formValidation.handleBlur(e);
                                            }}
                                        ></BaseSelect>
                                    </div>
                                    {formValidation.values["hasExpirationDate"] === true && (
                                        <div className="create-payment-link__settings-input">
                                            <BaseDatepicker
                                                label={`${t(StringResources.modal.createPaymentLink.validTo)}`}
                                                onDateChange={e =>
                                                    formValidation.setFieldValue("dateTo", e!.toISOString())
                                                }
                                                isClearable={false}
                                                value={formValidation.values["dateTo"]}
                                                minValue={getTodayDate()}
                                                invalid={
                                                    formValidation.errors["dateTo"]
                                                        ? formValidation.errors["dateTo"]
                                                        : false
                                                }
                                            ></BaseDatepicker>
                                        </div>
                                    )}
                                </div>

                                <div>
                                    <div className="create-payment-link__subtitle">{`${t(
                                        StringResources.modal.createPaymentLink.buyerInfoTitle
                                    )}`}</div>
                                    {formValidation.values["shopperInfoData"].map((item, index) => (
                                        <div key={item.field} className="create-payment-link__buyer-info-options">
                                            <div className="create-payment-link__buyer-info-options-label">
                                                {item.label}
                                            </div>

                                            <div className="create-payment-link__buyer-info-options-option">
                                                <BaseToggleSwitch
                                                    options={[
                                                        {
                                                            label: t(
                                                                StringResources.modal.createPaymentLink.hiddenLabel
                                                            ),
                                                            value: ShopperInfoRequirementType.Hidden,
                                                        },
                                                        {
                                                            label: t(
                                                                StringResources.modal.createPaymentLink.optionalabel
                                                            ),
                                                            value: ShopperInfoRequirementType.Optional,
                                                        },
                                                        {
                                                            label: t(
                                                                StringResources.modal.createPaymentLink.requiredLabel
                                                            ),
                                                            value: ShopperInfoRequirementType.Required,
                                                        },
                                                    ]}
                                                    activeValue={item.shopperInfoRequirementType}
                                                    onChange={option =>
                                                        formValidation.setFieldValue(
                                                            `shopperInfoData[${index}].shopperInfoRequirementType`,
                                                            option.value
                                                        )
                                                    }
                                                ></BaseToggleSwitch>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="create-payment-link__subtitle">
                                {`${t(StringResources.modal.createPaymentLink.salesTitle)}`}
                            </div>
                            <div className="create-payment-link__sales">
                                <div>
                                    <BaseRadio
                                        label={`${t(StringResources.modal.createPaymentLink.unlimitedSales)}`}
                                        name={"radio-2"}
                                        checked={
                                            formValidation.values["salesType"] ==
                                            CreatePaymentLinkSalesType.UnlimitedSales
                                        }
                                        value={CreatePaymentLinkSalesType.UnlimitedSales}
                                        className={"create-payment-link__sales--radio-group"}
                                        onChange={() => onBaseRadioChange(CreatePaymentLinkSalesType.UnlimitedSales)}
                                    ></BaseRadio>
                                </div>

                                <div>
                                    <BaseRadio
                                        label={`${t(StringResources.modal.createPaymentLink.exactSales)}`}
                                        name={"radio-3"}
                                        checked={
                                            formValidation.values["salesType"] == CreatePaymentLinkSalesType.ExactSales
                                        }
                                        value={CreatePaymentLinkSalesType.ExactSales}
                                        className={"create-payment-link__sales--radio-group"}
                                        onChange={() => onBaseRadioChange(CreatePaymentLinkSalesType.ExactSales)}
                                    ></BaseRadio>
                                </div>

                                <div>
                                    <BaseRadio
                                        label={`${t(StringResources.modal.createPaymentLink.singleSales)}`}
                                        name={"radio-1"}
                                        checked={
                                            formValidation.values["salesType"] == CreatePaymentLinkSalesType.SingleSales
                                        }
                                        value={CreatePaymentLinkSalesType.SingleSales}
                                        className={"create-payment-link__sales--radio-group"}
                                        onChange={() => onBaseRadioChange(CreatePaymentLinkSalesType.SingleSales)}
                                    ></BaseRadio>
                                </div>

                                {formValidation.values["salesType"] == CreatePaymentLinkSalesType.ExactSales && (
                                    <div className="create-payment-link__sales--item">
                                        <BaseInput
                                            label={`${t(StringResources.modal.createPaymentLink.exactSalesCount)}`}
                                            type={"number"}
                                            name="exactSalesCount"
                                            value={formValidation.values["exactSalesCount"]}
                                            onBlur={formValidation.handleBlur}
                                            onChange={formValidation.handleChange}
                                            disabled={
                                                formValidation.values["salesType"] !==
                                                CreatePaymentLinkSalesType.ExactSales
                                            }
                                        ></BaseInput>
                                    </div>
                                )}
                            </div>
                            <div className="create-payment-link__subtitle">{`${t(
                                StringResources.modal.createPaymentLink.productsTitle
                            )}`}</div>
                            <div className="create-payment-link__products">
                                <div className="create-payment-link__products-header-title">
                                    {`${t(StringResources.modal.createPaymentLink.productsDescription)}`}
                                </div>
                                <div className="create-payment-link__products-header-title">
                                    {`${t(StringResources.modal.createPaymentLink.productsQuantity)}`}
                                </div>
                                <div className="create-payment-link__products-header-title">
                                    {`${t(StringResources.modal.createPaymentLink.productsUnitPrice)}`}
                                </div>
                                <div className="create-payment-link__products-header-title">
                                    {`${t(StringResources.modal.createPaymentLink.productAmountWithoutDiscount)}`}
                                </div>
                                <div className="create-payment-link__products-header-title">
                                    {`${t(StringResources.modal.createPaymentLink.productsAmount)}`}
                                </div>
                                <div></div>
                                {formValidation.values["productValues"].map((prodValue, index) => {
                                    return (
                                        <Fragment key={prodValue.productDetailsId}>
                                            <div className="create-payment-link__products-row-input">
                                                <BaseInput
                                                    type="text"
                                                    name={`productValues[${index}].description`}
                                                    value={prodValue.description}
                                                    onBlur={formValidation.handleBlur}
                                                    onChange={formValidation.handleChange}
                                                />
                                            </div>
                                            <div className="create-payment-link__products-row-input">
                                                <BaseInput
                                                    type="number"
                                                    name={`productValues[${index}].quantity`}
                                                    value={prodValue.quantity}
                                                    onBlur={formValidation.handleBlur}
                                                    onChange={e => {
                                                        formValidation.handleChange(e);

                                                        formValidation.setFieldValue(
                                                            `productValues[${index}].amount`,
                                                            new Decimal(
                                                                Number(e.target.value ?? 0) * (prodValue.unitPrice ?? 0)
                                                            ).toNumber(),
                                                            true
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div className="create-payment-link__products-row-input">
                                                <BaseCurrencyInputATM
                                                    currency={formValidation.values["currencyId"]}
                                                    hideCurrency={true}
                                                    hideSymbol={true}
                                                    name={`productValues[${index}].unitPrice`}
                                                    value={prodValue.unitPrice}
                                                    onValueChange={async e => {
                                                        await formValidation.setFieldValue(
                                                            `productValues[${index}].unitPrice`,
                                                            e
                                                        );

                                                        await formValidation.setFieldValue(
                                                            `productValues[${index}].amount`,
                                                            new Decimal(e * (prodValue.quantity ?? 0)).toNumber(),
                                                            true
                                                        );
                                                    }}
                                                    onBlur={formValidation.handleBlur}
                                                />
                                            </div>
                                            <div className="create-payment-link__products-row-input">
                                                <BaseCurrencyInputATM
                                                    currency={formValidation.values["currencyId"]}
                                                    hideCurrency={true}
                                                    hideSymbol={true}
                                                    name={`productValues[${index}].amountWithoutDiscount`}
                                                    value={new Decimal(
                                                        (prodValue.quantity ?? 0) * (prodValue.unitPrice ?? 0)
                                                    ).toNumber()}
                                                    onValueChange={() => {
                                                        return;
                                                    }}
                                                    disabled={true}
                                                />
                                            </div>
                                            <div className="create-payment-link__products-row-input">
                                                <BaseCurrencyInputATM
                                                    currency={formValidation.values["currencyId"]}
                                                    hideCurrency={true}
                                                    hideSymbol={true}
                                                    name={`productValues[${index}].amount`}
                                                    value={prodValue.amount}
                                                    onValueChange={e =>
                                                        formValidation.setFieldValue(
                                                            `productValues[${index}].amount`,
                                                            e,
                                                            true
                                                        )
                                                    }
                                                    onBlur={formValidation.handleBlur}
                                                />
                                            </div>
                                            <BaseButton
                                                styleType="text"
                                                disabled={formValidation.values["productValues"].length === 1}
                                                handleClick={() =>
                                                    formValidation.setFieldValue(
                                                        "productValues",
                                                        formValidation.values["productValues"].filter(
                                                            (_, i) => i !== index
                                                        )
                                                    )
                                                }
                                                leftIcon={<CancelInputIcon />}
                                            />
                                        </Fragment>
                                    );
                                })}
                            </div>
                            <BaseButton
                                text={`${t(StringResources.modal.createPaymentLink.addProductButton)}`}
                                styleType="line"
                                size="small"
                                leftIcon={<PlussAddIcon />}
                                handleClick={() => {
                                    formValidation.setFieldValue("productValues", [
                                        ...formValidation.values["productValues"],
                                        {
                                            productDetailsId: createUUID(),
                                            description: undefined,
                                            quantity: 1,
                                            amountWithoutDiscount: 0,
                                            unitPrice: 0,
                                            amount: 0,
                                        },
                                    ]);
                                }}
                            ></BaseButton>
                            <div className="create-payment-link__total">
                                <div>
                                    {`${t(StringResources.modal.createPaymentLink.totalAmount)}`} :{" "}
                                    {formatter(calculateTotalAmount(), formValidation.values["currencyId"])}
                                </div>
                                {formValidation.errors && (
                                    <div className="create-payment-link__total-error">{getFirstFormikError()}</div>
                                )}
                            </div>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer className={classNames({ "modal-border-top": true })}>
                    <BaseButton
                        handleClick={onCancelClick}
                        text={`${t(StringResources.modal.cancel)}`}
                        styleType="line"
                    />

                    <BaseButton
                        handleClick={onOkClick}
                        text={props.actionButtonText}
                        styleType="solid"
                        disabled={!(formValidation.isValid && !formValidation.isValidating) && !isLoading}
                    />
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default CreatePaymentLinkModal;
