import classNames from "classnames";
import BaseButton from "components/baseButton/baseButton";
import BaseInput from "components/baseInput/baseInput";
import ColorPicker from "components/colorPicker/colorPicker";
import { CopyToClipboardIcon, TrashIcon } from "components/icons";
import LogoUploader from "components/logoUploader/logoUploader";
import { useFormik } from "formik";
import { IGetEcomThemesResponseListData } from "pages/ecomThemes/api/ecomThemesModels";
import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { confirmModal, declineModal } from "redux/reducers/modalReducer";
import { executeAxiosRequestWithRefresh } from "redux/services";
import { RootState, useAppDispatch } from "redux/store";
import { useModalManagement } from "utils/customHooks";
import { LogoStatusEnum, ModalActionButtonTypeEnum, ModalTypeEnum } from "utils/enums";
import { getContentDispositionFilenameUtf8, getFile } from "utils/helperFunctions";
import { StringResources } from "utils/language/languageResource";
import * as Yup from "yup";
import variable from "../../../style/colors.module.scss";
import "./createOrEditEcomThemeModal.scss";
import BaseCheckbox from "components/baseCheckbox/baseCheckbox";

interface ICreateOrEditEcomThemeForm {
    themeKey: string;
    name: string;
    description: string;
    colorPalette: string;
    logo: File | null;
    logoStatus: LogoStatusEnum;
    merchantId?: string;
    isDefaultTheme: boolean;
}

interface ICreateOrEditThemeModalProps {
    data: IGetEcomThemesResponseListData;
}

const CreateOrEditEcomThemeModal = (props: ICreateOrEditThemeModalProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const modalManagement = useModalManagement();
    const [fetchedLogo, setLogo] = useState<File | null>(null);
    const activeMerchant = useSelector((state: RootState) => state.user.activeMerchant);

    useEffect(() => {
        if (props.data) {
            fetchEcomThemeLogo();
        }
    }, []);

    const fetchEcomThemeLogo = async () => {
        try {
            const response = await executeAxiosRequestWithRefresh({
                url: `/api/ecom/fetch`,
                responseType: "blob",
                method: "GET",
                params: {
                    themeKey: props.data.themeKey,
                },
            });

            if (response.data) {
                const filename = getContentDispositionFilenameUtf8(response.headers);
                const logoFile = getFile(response.data, filename, response.contentType!);
                setLogo(logoFile);
            }
        } catch (error: any) {}
    };

    const formValidation = useFormik<ICreateOrEditEcomThemeForm>({
        enableReinitialize: true,
        validateOnChange: true,
        validateOnBlur: true,
        validateOnMount: true,
        initialValues: {
            themeKey: props.data?.themeKey ?? "",
            name: props.data?.name ?? "",
            description: props.data?.description ?? "",
            colorPalette: props.data?.colorPalette ?? "",
            logo: null,
            logoStatus: LogoStatusEnum.NoChange,
            merchantId: activeMerchant?.id,
            isDefaultTheme: props.data?.isDefaultTheme ?? false,
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(t(StringResources.pages.ecomThemes.error.nameIsRequired)),
            description: Yup.string().optional(),
            colorPalette: Yup.string()
                .matches(/^#[0-9A-F]{6}$/i, t(StringResources.pages.ecomThemes.error.colorValidation))
                .required(t(StringResources.pages.ecomThemes.error.colorIsRequired)),
            logo: Yup.mixed().optional(),
            isDefaultTheme: Yup.boolean().required(),
        }),
        onSubmit: async (value: ICreateOrEditEcomThemeForm) => {
            try {
                if (props.data) {
                    await executeAxiosRequestWithRefresh({
                        url: "/api/ecom/update",
                        method: "POST",
                        data: {
                            id: props.data?.id,
                            name: value.name,
                            description: value.description,
                            colorPalette: value.colorPalette,
                            logo: value.logo,
                            logoStatus: value.logoStatus,
                            isDefaultTheme: value.isDefaultTheme,
                        },
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });

                    dispatch(confirmModal({ modalType: ModalTypeEnum.CreateOrEditEcomTheme }));
                    toast.success(t(StringResources.pages.ecomThemes.edit.successMsg).toString());
                    return;
                }

                await executeAxiosRequestWithRefresh({
                    url: "/api/ecom/create",
                    method: "POST",
                    data: {
                        name: value.name,
                        description: value.description,
                        colorPalette: value.colorPalette,
                        logo: value.logo,
                        merchantId: activeMerchant?.id,
                        isDefaultTheme: value.isDefaultTheme,
                    },
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                dispatch(confirmModal({ modalType: ModalTypeEnum.CreateOrEditEcomTheme }));
                toast.success(t(StringResources.pages.ecomThemes.create.successMsg).toString());
            } catch (error: any) {
                toast.error(t(StringResources.pages.terminals.error.submitError).toString());
            }
        },
    });

    const copyToClipboard = async () => {
        try {
            navigator.clipboard.writeText(formValidation.values["themeKey"]);
            toast.success(t(StringResources.pages.ecomThemes.edit.copyToClipboard).toString());
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const handleColorChange = async (color: string) => {
        await formValidation.setFieldValue("colorPalette", color);
    };

    const handleColorBlur = async () => {
        await formValidation.validateField("colorPalette");
        await formValidation.setFieldTouched("colorPalette", true);
    };

    const handleLogoUpload = async (file: File) => {
        await formValidation.setFieldValue("logo", file);
        await formValidation.setFieldValue("logoStatus", LogoStatusEnum.Upload);
    };

    const handleLogoRemove = async () => {
        await formValidation.setFieldValue("logo", null);
        await formValidation.setFieldValue("logoStatus", fetchedLogo ? LogoStatusEnum.Remove : LogoStatusEnum.NoChange);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        formValidation.handleSubmit();
    };

    const onDeleteClick = async () => {
        const modalData = await modalManagement.openModal({
            modalType: ModalTypeEnum.Confirm,
            props: {
                title: `${t(StringResources.pages.ecomThemes.delete.deleteTitle)}`,
                text: `${t(StringResources.pages.ecomThemes.delete.deleteMessage)}`,
                modalType: ModalTypeEnum.Confirm,
                modalActionButtonType: ModalActionButtonTypeEnum.Delete,
                actionButtonText: t(StringResources.modal.delete),
                closeButtonText: t(StringResources.modal.cancel),
            },
        });

        if (modalData !== null) {
            try {
                await executeAxiosRequestWithRefresh({
                    url: "/api/ecom/delete",
                    method: "POST",
                    data: {
                        ecomThemeId: props.data.id,
                    },
                });

                dispatch(confirmModal({ modalType: ModalTypeEnum.CreateOrEditEcomTheme }));
                toast.success(t(StringResources.pages.ecomThemes.delete.successMsg).toString());
            } catch (error: any) {}
        }
    };

    const onCancelClick = () => {
        dispatch(declineModal({ modalType: ModalTypeEnum.CreateOrEditEcomTheme }));
    };

    const onOkClick = () => {
        formValidation.handleSubmit();
    };

    return (
        <Modal show={true} onHide={onCancelClick} dialogClassName="base-modal">
            <Form onSubmit={handleSubmit} noValidate>
                <Modal.Header closeButton className={classNames({ "modal-border-bottom": true })}>
                    <Modal.Title>
                        {!props.data
                            ? `${t(StringResources.pages.ecomThemes.create.title)}`
                            : `${t(StringResources.pages.ecomThemes.edit.editTheme)}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="create-or-edit-ecom-theme__modal-body">
                    {props.data && (
                        <Form.Group className="create-or-edit-ecom-theme__form-row">
                            <BaseInput
                                type={"text"}
                                name={"themeKey"}
                                autocomplete={"off"}
                                label={`${t(StringResources.pages.ecomThemes.create.themeKey)}`}
                                value={formValidation.values["themeKey"]}
                                invalid={formValidation.touched["themeKey"] && formValidation.errors["themeKey"]}
                                onBlur={formValidation.handleBlur}
                                onChange={formValidation.handleChange}
                                disabled={true}
                            />

                            <button
                                type="button"
                                onClick={copyToClipboard}
                                className="create-or-edit-ecom-theme__clipboard-button"
                            >
                                <CopyToClipboardIcon />
                            </button>
                        </Form.Group>
                    )}

                    <div className="create-or-edit-ecom-theme__general-theme-info">
                        <p className="create-or-edit-ecom-theme__general-theme-info-title">{`${t(
                            StringResources.pages.ecomThemes.create.generalThemeInfo
                        )}`}</p>
                    </div>

                    <Form.Group className="create-or-edit-ecom-theme__form-row">
                        <BaseInput
                            type={"text"}
                            name={"name"}
                            autocomplete={"off"}
                            label={`${t(StringResources.pages.ecomThemes.create.name)}`}
                            value={formValidation.values["name"]}
                            invalid={formValidation.touched["name"] && formValidation.errors["name"]}
                            onBlur={formValidation.handleBlur}
                            onChange={formValidation.handleChange}
                        />
                    </Form.Group>

                    <Form.Group className="create-or-edit-ecom-theme__form-row">
                        <BaseInput
                            type={"text"}
                            name={"description"}
                            autocomplete={"off"}
                            label={`${t(StringResources.pages.ecomThemes.create.description)}`}
                            value={formValidation.values["description"]}
                            invalid={formValidation.touched["description"] && formValidation.errors["description"]}
                            onBlur={formValidation.handleBlur}
                            onChange={formValidation.handleChange}
                        />
                    </Form.Group>

                    <div className="create-or-edit-ecom-theme__design">
                        <p className="create-or-edit-ecom-theme__design-title">{`${t(
                            StringResources.pages.ecomThemes.create.design
                        )}`}</p>
                    </div>

                    <Form.Group className="create-or-edit-ecom-theme__form-row">
                        <ColorPicker
                            colorPalette={formValidation.values["colorPalette"] ?? ""}
                            onChange={handleColorChange}
                            onBlur={handleColorBlur}
                        />
                        {formValidation.touched["colorPalette"] && formValidation.errors["colorPalette"] && (
                            <p className="form-control-error">{formValidation.errors["colorPalette"]}</p>
                        )}
                    </Form.Group>

                    <div className="create-or-edit-ecom-theme__logo">
                        <p className="create-or-edit-ecom-theme__logo-title">{`${t(
                            StringResources.pages.ecomThemes.create.logo
                        )}`}</p>
                    </div>

                    <Form.Group className="create-or-edit-ecom-theme__form-row">
                        <LogoUploader
                            onLogoUpload={handleLogoUpload}
                            onLogoRemove={handleLogoRemove}
                            maxFileSize={50}
                            maxWidth={280}
                            maxHeigth={64}
                            uploadedLogo={
                                fetchedLogo
                                    ? formValidation.values.logoStatus === LogoStatusEnum.Remove
                                        ? undefined
                                        : fetchedLogo
                                    : undefined
                            }
                        />
                    </Form.Group>

                    <Form.Group className="create-or-edit-ecom-theme__form-row">
                        <BaseCheckbox
                            id={"isDefaultTheme"}
                            checked={formValidation.values["isDefaultTheme"]}
                            onChange={(_, checked) => formValidation.setFieldValue("isDefaultTheme", checked)}
                            label={`${t(StringResources.pages.ecomThemes.create.isDefaultTheme)}`}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer className={classNames("create-or-edit-ecom-theme__footer", { "modal-border-top": true })}>
                    {props.data && (
                        <BaseButton
                            handleClick={onDeleteClick}
                            text={`${t(StringResources.modal.delete)}`}
                            styleType="text"
                            danger={true}
                            leftIcon={<TrashIcon color={variable.colorRedAlpha} />}
                        />
                    )}

                    <div className="create-or-edit-ecom-theme__footer-form-buttons">
                        <BaseButton
                            handleClick={onCancelClick}
                            text={`${t(StringResources.modal.close)}`}
                            styleType="line"
                        />

                        <BaseButton
                            handleClick={onOkClick}
                            text={
                                !props.data
                                    ? `${t(StringResources.pages.ecomThemes.create.create)}`
                                    : `${t(StringResources.pages.ecomThemes.edit.save)}`
                            }
                            styleType="solid"
                            disabled={!(formValidation.isValid && formValidation.dirty && !formValidation.isValidating)}
                        />
                    </div>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default CreateOrEditEcomThemeModal;
