import "hammerjs";
import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { RootState, useAppDispatch } from "redux/store";
import { LoginStateEnum, PermissionType } from "utils/enums";
import { PATHS } from "utils/routing/paths";
import { KycWrapperLazy, PortalRouteElements, PortalWrapperLazy, globalElements } from "utils/routing/pathsAndElements";
import { FallbackComponent } from "./fallbackComponent";
import GlobalRoutesWrapper from "./wrappers/globalRoutesWrapper";
import { ActivityTracker } from "containers/activityTracker/activityTracker";
import { useBroadcastAuthenticationChannel } from "utils/customHooks";

const SettingsLazy = React.lazy(() => import("../../pages/settings/settings"));
const TermsOfServiceLazy = React.lazy(() => import("../../pages/termsOfService/termsOfService"));
const NotFoundLazy = React.lazy(() => import("../../pages/notFound/notFound"));
const DisputesNewLazy = React.lazy(() => import("../../pages/newDisputes/disputes"));
const KycLazy = React.lazy(() => import("../../pages/kyc/kyc"));
const KycLegalEntityLazy = React.lazy(() => import("../../pages/kycLegalEntity/kycLegalEntity"));
const KycSurveyLazy = React.lazy(() => import("../../pages/kycSurvey/kycSurvey"));
const KycSuccessLazy = React.lazy(() => import("../../pages/kycSuccess/kycSuccess"));

const MainLayout = () => {
    const dispatch = useAppDispatch();
    const authLoginState = useSelector((state: RootState) => state.auth.loginState);
    const userPermissions = useSelector((state: RootState) => state.user.permissions);
    const selectedMerchant = useSelector((state: RootState) => state.user.selectedMerchant);

    const hasMerchantValidTerms = selectedMerchant?.hasAtLeastOneAcceptedTerms;

    useBroadcastAuthenticationChannel(dispatch);

    return (
        <Suspense fallback={<FallbackComponent />}>
            {authLoginState === LoginStateEnum.LoggedIn && <ActivityTracker />}

            <Routes>
                {selectedMerchant && !hasMerchantValidTerms ? (
                    <>
                        <Route index element={<TermsOfServiceLazy />} />
                        <Route path={"*"} element={<TermsOfServiceLazy />} />
                    </>
                ) : (
                    <>
                        {globalElements.map(element => {
                            return (
                                <Route
                                    key={element.path}
                                    path={element.path}
                                    element={
                                        <GlobalRoutesWrapper>
                                            <element.element />
                                        </GlobalRoutesWrapper>
                                    }
                                />
                            );
                        })}

                        <Route path={PATHS.Global.Kyc} element={<KycWrapperLazy />}>
                            <Route index element={<KycLazy />}></Route>
                            <Route path={PATHS.Global.KycLegalEntity} element={<KycLegalEntityLazy />}></Route>
                            <Route path={PATHS.Global.KycSurvey} element={<KycSurveyLazy />}></Route>
                            <Route path={PATHS.Global.KycSuccess} element={<KycSuccessLazy />}></Route>
                        </Route>

                        <Route path={PATHS.Portal.Index} element={<PortalWrapperLazy />}>
                            {userPermissions.length &&
                                PortalRouteElements.map(element => {
                                    const hasPermissions = userPermissions.some(
                                        x => x === PermissionType[element.permission]
                                    );
                                    return (
                                        <Route
                                            key={element.path}
                                            path={element.path}
                                            element={hasPermissions ? <element.element /> : <NotFoundLazy />}
                                        />
                                    );
                                })}

                            {userPermissions.length > 0 &&
                                userPermissions.some(x => x === PermissionType[PermissionType.DisputesRead]) && (
                                    <Route path={PATHS.Portal.DisputesNew} element={<DisputesNewLazy />} />
                                )}

                            <Route path={PATHS.Portal.Settings} element={<SettingsLazy />} />
                            <Route path={"*"} element={<NotFoundLazy />} />
                        </Route>
                    </>
                )}
            </Routes>
        </Suspense>
    );
};

export default MainLayout;
