import classNames from "classnames";
import BaseButton from "components/baseButton/baseButton";
import BaseCheckbox from "components/baseCheckbox/baseCheckbox";
import BaseInput from "components/baseInput/baseInput";
import BaseMultiselect, { Option } from "components/baseMultiselect/baseMultiselect";
import { useFormik } from "formik";
import { t } from "i18next";
import { Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { confirmModal, declineModal } from "redux/reducers/modalReducer";
import { executeAxiosRequestWithRefresh } from "redux/services";
import { RootState, useAppDispatch } from "redux/store";
import { ModalTypeEnum } from "utils/enums";
import { StringResources } from "utils/language/languageResource";
import { PageTypeEnum, ReportType } from "utils/reportDefinitions";
import * as Yup from "yup";
import "./createOrEditApiKeyModal.scss";
import BaseBanner from "components/baseBanner/baseBanner";

interface ICreateOrEditApiKeyModalProps {
    title: string;
    actionButtonText: string;

    data: {
        keyName?: string;
        apiKeyId?: number;
        isSubscribedToAllContracts?: boolean;
        contractIds?: Array<number>;
    };
}

const CreateOrEditApiKeyModal = (props: ICreateOrEditApiKeyModalProps) => {
    const { apiKeyId, contractIds, isSubscribedToAllContracts, keyName } = props.data;
    const contractData = useSelector((state: RootState) => state.dashboard.contractData);
    const dispatch = useAppDispatch();

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        formValidation.handleSubmit();
    };

    const formValidation = useFormik({
        enableReinitialize: true,
        validateOnBlur: true,
        initialValues: {
            keyName: keyName ?? "",
            apiKeyId: apiKeyId,
            isSubscribedToAllContracts: isSubscribedToAllContracts ?? false,
            contractIds: contractIds,
        },
        validationSchema: Yup.object({
            keyName: Yup.string().required(`${t(StringResources.modal.apiKeys.keyNameIsRequired)}`),
            isSubscribedToAllContracts: Yup.boolean().optional(),
            contractIds: Yup.array()
                .required()
                .when("isSubscribedToAllContracts", (isSubscribedToAllContracts: boolean, schema) => {
                    if (isSubscribedToAllContracts) {
                        return schema.min(0);
                    }
                    return schema.min(1);
                }),
        }),
        onSubmit: async (value: any) => {
            if (apiKeyId) {
                try {
                    await executeAxiosRequestWithRefresh({
                        url: "/api/users/edit-api-key",
                        method: "POST",
                        data: {
                            apiKeyId: value.apiKeyId,
                            keyName: value.keyName,
                            isSubscribedToAllContracts: value.isSubscribedToAllContracts,
                            contractIds: value.contractIds,
                        },
                    });

                    dispatch(confirmModal({ modalType: ModalTypeEnum.CreateOrEditApiKey }));
                    toast.success(`${t(StringResources.pages[ReportType.ApiKeys].editKeySuccess)}`);
                } catch (error) {
                    toast.error(`${t(StringResources.pages[ReportType.ApiKeys].editKeyError)}`);
                }

                return;
            }

            try {
                await executeAxiosRequestWithRefresh({
                    url: "/api/users/generate-new-api-key",
                    method: "POST",
                    data: {
                        keyName: value.keyName,
                        isSubscribedToAllContracts: value.isSubscribedToAllContracts,
                        contractIds: value.contractIds,
                    },
                });

                dispatch(confirmModal({ modalType: ModalTypeEnum.CreateOrEditApiKey }));
                toast.success(`${t(StringResources.pages[ReportType.ApiKeys].generateNewKeySuccess)}`);
            } catch (error) {
                toast.error(`${t(StringResources.pages[ReportType.ApiKeys].generateNewKeyError)}`);
            }
        },
    });

    const onCancelClick = () => {
        dispatch(declineModal({ modalType: ModalTypeEnum.CreateOrEditApiKey }));
    };

    const onSubmit = async () => {
        await formValidation.handleSubmit();
    };

    const setIsSubscribedToAllContracts = async () => {
        await formValidation.handleBlur("isSubscribedToAllContracts");
        await formValidation.setFieldValue(
            "isSubscribedToAllContracts",
            !formValidation.values["isSubscribedToAllContracts"]
        );

        if (!formValidation.values["isSubscribedToAllContracts"]) {
            await formValidation.setFieldValue("contractIds", []);
        }

        await formValidation.validateField("contractIds");
    };

    const isFormValid = formValidation.dirty && formValidation.isValid;

    return (
        <Modal show={true} onHide={onCancelClick} dialogClassName="base-modal create-edit-api-keys">
            <Form onSubmit={handleSubmit} noValidate>
                <Modal.Header closeButton className={classNames({ "modal-border-bottom": true })}>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!props.data.apiKeyId && (
                        <BaseBanner text={`${t(StringResources.pages.apiKeys.generateNewKeyBannerMessage)}`} />
                    )}
                    <BaseInput
                        type={"text"}
                        name={"keyName"}
                        autocomplete={"off"}
                        label={`${t(StringResources.pages.apiKeys.keyName)}`}
                        value={formValidation.values["keyName"]}
                        invalid={
                            formValidation.touched["keyName"] && typeof formValidation.errors["keyName"] === "string"
                                ? formValidation.errors["keyName"]
                                : undefined
                        }
                        onBlur={formValidation.handleBlur}
                        onChange={formValidation.handleChange}
                    />
                    <BaseMultiselect
                        disabled={formValidation.values["isSubscribedToAllContracts"]}
                        options={
                            contractData.map(x => {
                                return {
                                    value: x.contractId,
                                    label: x.contractNumber,
                                    selected:
                                        formValidation.values["contractIds"]?.some((y: number) => y === x.contractId) ??
                                        false,
                                };
                            }) ?? []
                        }
                        showSelectAll={true}
                        label={`${t(StringResources.pages[PageTypeEnum.ApiKeys].contracts)}`}
                        onChange={async (selectedItems: Option[]) => {
                            await formValidation.handleBlur("contractIds");
                            await formValidation.setFieldValue(
                                "contractIds",
                                selectedItems.map(x => x.value),
                                true
                            );

                            await formValidation.validateField("contractIds");
                        }}
                    />
                    <BaseCheckbox
                        id={"isSubscribedToAllContracts"}
                        checked={formValidation.values["isSubscribedToAllContracts"] ?? false}
                        onChange={setIsSubscribedToAllContracts}
                        label={`${t(StringResources.pages.apiKeys.isSubscribedToAllContracts)}`}
                    />
                </Modal.Body>
                <Modal.Footer className={classNames({ "modal-border-top": true })}>
                    <BaseButton
                        handleClick={onCancelClick}
                        text={`${t(StringResources.modal.cancel)}`}
                        styleType="line"
                    />

                    <BaseButton
                        handleClick={onSubmit}
                        text={props.actionButtonText}
                        styleType="solid"
                        disabled={!isFormValid}
                    />
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default CreateOrEditApiKeyModal;
