import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { CurrenciesConfig, Currency } from "utils/formatter";
import "./baseCurrencyInput.scss";

interface IBaseCurrencyInputProps {
    currency: Currency;
    label?: string;
    value?: number;
    disabled?: boolean;
    onValueChange?: (value: number) => void;
    name?: string;
    invalid?: string | undefined | boolean;
    hideSymbol?: boolean | undefined;
    hideCurrency?: boolean | undefined;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

// Flow of the component:
// 1. Input is rendered with 0,00 value.
// 2. User clicks on the input and the cursor is moved to the end of the value.
// 3. User types a number.
// 4. The value is formatted to the currency ATM format.
// 5. The value is updated to the formatted value.
// 6. onValueChange is triggered with the value formatted to number.
// 7. Prop.Value is updated in parent component and returned to this component.
// 8. Component accepts Prop.Value, formats it and updates the local value.
const BaseCurrencyInputATM = (props: IBaseCurrencyInputProps) => {
    const currency = CurrenciesConfig.find(currencyConfig => currencyConfig.code === props.currency)!;

    const [localValue, setLocalValue] = useState(currency.decimalLimit === 0 ? "0" : "0,00");
    const inputRef = useRef(null);

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        props.onBlur && props.onBlur(e);
    };

    const moveCursorToEnd = () => {
        if (inputRef.current) {
            const length = localValue.length;
            (inputRef.current as any).setSelectionRange(length, length);
        }
    };

    const handleKeyDown = (event: any) => {
        // Disable the user to type anything else than numbers or backspace to delete.
        if (event.key == "Backspace" || event.key == "-") {
            return event;
        } else if (isNaN(event.key) || event.key == " ") {
            event.preventDefault();
        }
    };
    useEffect(() => {
        if (props.value != undefined) {
            if (currency.decimalLimit === 0) {
                const propsFormattedValue = Intl.NumberFormat("de-DE", {
                    minimumFractionDigits: 0, // Ensure no decimal places
                    maximumFractionDigits: 0, // Ensure no decimal places
                }).format(props.value);
                setLocalValue(propsFormattedValue);

                return;
            }

            const propsFormattedValue = Intl.NumberFormat("de-DE", {
                minimumFractionDigits: 2, // Ensure at least two decimal places
                maximumFractionDigits: 2, // Ensure no more than two decimal places
            }).format(props.value);
            setLocalValue(propsFormattedValue);
        }
        moveCursorToEnd();
    }, [props.value, currency]);

    // 1. 0,002 => 00,02 => 0,02
    // 2. 0,023 => 00,23 => 0,23
    // 3. 0,234 => 02,34 => 2,34
    // 4. 2,346 => 23,46
    // 5. backspace or delete => 2,34
    const formatValueToATMStandard = (value: string) => {
        if (currency.decimalLimit === 0) {
            if (value === "") {
                return "0";
            }

            return value;
        }

        const valueWithoutDecimalSeparator = value.replace(",", "");
        const indexOfDecimalSeparator = value.indexOf(",");
        const decimalPart = valueWithoutDecimalSeparator.slice(indexOfDecimalSeparator);
        const integerPart = valueWithoutDecimalSeparator.slice(0, indexOfDecimalSeparator);

        // User added number to the decimal part
        if (decimalPart.length > 2) {
            // Take right most two numbers from the decimal part
            const newDecimalPart = decimalPart.slice(decimalPart.length - 2, decimalPart.length);
            // Take the rest on the left side of the numbers from the decimal part
            const extraDecimalPart = decimalPart.slice(0, decimalPart.length - 2);

            // Add the extra decimal part to the integer part
            const newIntegerPart = integerPart + extraDecimalPart;

            // Trim the left side leading zeros.
            return newIntegerPart.replace(/^0+/, "") + "," + newDecimalPart;
        } else if (decimalPart.length === 1) {
            // User deleted number from the decimal part
            // Take the right most number from the integer part
            const extraIntegerPart = integerPart.slice(integerPart.length - 1);
            // Take the rest on the left side of the numbers from the integer part
            const newIntegerPart = integerPart.slice(0, integerPart.length - 1);

            if (newIntegerPart.length === 0) {
                return "0," + extraIntegerPart + decimalPart;
            }

            // Add the extra integer part to the decimal part
            return newIntegerPart.replace(/^0+/, "") + "," + extraIntegerPart + decimalPart;
        } else if (decimalPart.length === 0) {
            return integerPart + ",00";
        }

        return integerPart + "," + decimalPart;
    };

    const handleChange = (event: any) => {
        // Format the value to the currency ATM format.
        const negativeValueCount = event.target.value.split("-").length - 1;

        if (negativeValueCount > 0) {
            event.target.value = event.target.value.replaceAll("-", "");
        }

        var formattedValue = formatValueToATMStandard(event.target.value);

        if (negativeValueCount == 1) {
            formattedValue = "-" + formattedValue;
        }

        setLocalValue(formattedValue);

        if (props.onValueChange) {
            // Parse the value to a number.
            const parsedValue = parseFloat(formattedValue.replace(/\./g, "").replace(",", "."));
            props.onValueChange(parsedValue);
        }
    };

    return (
        <div
            className={classNames("currency-input-wrapper", {
                "currency-input-wrapper--disabled": props.disabled,
            })}
        >
            {(props.hideCurrency === undefined || !props.hideCurrency) && (
                <div className="currency-input__prefix">{currency?.code}</div>
            )}
            <input
                className="currency-input__form-control"
                type="text"
                name={props.name}
                ref={inputRef}
                value={localValue}
                onBlur={handleBlur}
                onChange={handleChange}
                onClick={moveCursorToEnd}
                onSelect={moveCursorToEnd}
                onKeyDown={handleKeyDown}
                onPaste={(e: any) => {
                    e.preventDefault();
                    return false;
                }}
            />
            {(props.hideSymbol === undefined || !props.hideSymbol) && (
                <div className="currency-input__suffix">{currency?.symbol}</div>
            )}
        </div>
    );
};

export default BaseCurrencyInputATM;
