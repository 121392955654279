import { baseApi, standardQueryTableRequest } from "api/baseApi";
import { IQueryParams } from "api/requestModels";
import { getDisputeReasonCodeValue } from "utils/helperFunctions";
import { IDisputeNewModel } from "./disputeModel";

export const baseDisputesRoute = "/api/disputes";
export const disputesExportRoute = `${baseDisputesRoute}/report`;

const disputesNewApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        getDisputesNew: builder.query<IDisputeNewModel, IQueryParams>({
            query: standardQueryTableRequest<IDisputeNewModel>(baseDisputesRoute + "/list"),
            transformResponse: (response: IDisputeNewModel) => {
                return {
                    ...response,
                    data: response.data.map(x => {
                        return {
                            ...x,
                            reasonCodeValue: getDisputeReasonCodeValue(x.reasonCode, false),
                            reasonCodeCategory: getDisputeReasonCodeValue(x.reasonCode, true),
                        };
                    }),
                };
            },
        }),
    }),
    overrideExisting: false,
});

export const { useGetDisputesNewQuery, endpoints } = disputesNewApi;
